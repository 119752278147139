import React, { Component } from "react";
import { connect } from "react-redux";
import { Store } from "redux";

import Accordions from "components/Accordions/Accordions";
import {
  ActionType,
  FullLooksType,
  MakeUpDataType,
  MakeUpEntitiesType
} from "types/common";
import {
  putMakeUpStatus,
  selectEyeShadowsLooksData,
  selectMakeUpData
} from "ducks/makeUp";
import AccordionsFullLooks from "components/AccordionsFullLooks/AccordionsFullLooks";
import EyeShadowIcon from "static/img/icons/eyeshadow.png";

import styles from "./MakeUpSelector.module.scss";

interface Props {
  currentMakeUp: MakeUpEntitiesType;
  fullLooks: FullLooksType;
  putMakeUpStatus: (
    currentPaletteId: string,
    makeUp: MakeUpDataType
  ) => ActionType;
}

interface State {
  openedAccordionType: string | null;
}

class MakeUpSelector extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      openedAccordionType: null
    };
  }

  public onAccordionClick = (type: string) => () => {
    this.setState({
      openedAccordionType: this.state.openedAccordionType !== type ? type : null
    });
  };

  public render() {
    const { currentMakeUp, putMakeUpStatus, fullLooks } = this.props;
    const { openedAccordionType } = this.state;

    return (
      <div className={styles.root}>
        <div className={styles.makeUpContainer}>
          <Accordions
            onAccordionClick={this.onAccordionClick}
            currentMakeUp={currentMakeUp}
            openedAccordionType={openedAccordionType}
            putMakeUpStatus={putMakeUpStatus}
          />
          {/* <AccordionsFullLooks
            name="eyeshadow"
            icon={EyeShadowIcon}
            onAccordionClick={this.onAccordionClick}
            openedAccordionType={openedAccordionType}
            fullLooks={fullLooks}
          /> */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: Store) => {
  return {
    currentMakeUp: selectMakeUpData(state),
    fullLooks: selectEyeShadowsLooksData(state)
  };
};

const mapDispatchToProps = {
  putMakeUpStatus
};

export default connect(mapStateToProps, mapDispatchToProps)(MakeUpSelector);
