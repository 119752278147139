import React, { Component } from "react";
import { connect } from "react-redux";
import { Icon } from "semantic-ui-react";
import classNames from "classnames";
import { Range } from "react-input-range";
import { Store } from "redux";
import { injectIntl, WrappedComponentProps } from "react-intl";

import {
  selectCanvasButtonTitleCamera,
  selectCanvasButtonTitleDownload,
  selectCanvasButtonTitleRemove,
  selectCanvasButtonTitleSplitter,
  selectCanvasButtonTitleZoomIn,
  selectCanvasButtonTitleZoomOut,
  selectIntensityLevel,
  selectIsLive,
  selectIsMultiCamera,
  selectZoomValue
} from "ducks/app";
import { MAX_ZOOM } from "utils/constants";
import InputRange from "components/InputRange/InputRange";

import styles from "./CanvasControls.module.scss";

interface Props extends WrappedComponentProps {
  zoomValue: number;
  intensityLevel: number;
  isResetVisible: boolean;
  isSplitterActive: boolean;
  isMultiCamera: boolean;
  isVideo: boolean;
  buttonTitleRemove: string;
  buttonTitleZoomIn: string;
  buttonTitleZoomOut: string;
  buttonTitleSplitter: string;
  buttonTitleDownload: string;
  buttonTitleCamera: string;
  resetView: () => void;
  toggleCamera: () => void;
  toggleSplitter: () => void;
  downloadImage: () => void;
  setIntensity: (value: number | Range) => void;
  changeZoom: (value: number) => () => void;
}

interface State {
  intensity: number;
}

class CanvasControls extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      intensity: 75
    };
  }

  public componentDidUpdate(prevProps: Readonly<Props>) {
    if (this.props.intensityLevel === 75 && prevProps.intensityLevel !== 75) {
      this.setState({ intensity: 75 });
    }
  }

  public changeIntensity = (value: number | Range) => {
    this.setState({ intensity: value as number });
  };

  public render() {
    const { intl } = this.props;

    return (
      <>
        <div className={styles.buttonContainer}>
          {this.props.isResetVisible && (
            <button
              className={styles.button}
              onClick={this.props.resetView}
              title={intl.formatMessage({ id: this.props.buttonTitleRemove })}
            >
              <Icon name="undo" />
            </button>
          )}
          <button
            className={classNames(styles.button, {
              [styles.disabled]: this.props.zoomValue === MAX_ZOOM - 1
            })}
            onClick={this.props.changeZoom(1)}
            title={intl.formatMessage({ id: this.props.buttonTitleZoomIn })}
          >
            <Icon name="zoom-in" />
          </button>
          <button
            className={classNames(styles.button, {
              [styles.disabled]: this.props.zoomValue === 0
            })}
            onClick={this.props.changeZoom(-1)}
            title={intl.formatMessage({ id: this.props.buttonTitleZoomOut })}
          >
            <Icon name="zoom-out" />
          </button>
          {this.props.isMultiCamera && this.props.isVideo && (
            <button
              className={styles.button}
              onClick={this.props.toggleCamera}
              title={intl.formatMessage({ id: this.props.buttonTitleCamera })}
            >
              <Icon name="refresh" />
            </button>
          )}
          <button
            className={classNames(styles.button, {
              [styles.active]: this.props.isSplitterActive
            })}
            onClick={this.props.toggleSplitter}
            title={intl.formatMessage({ id: this.props.buttonTitleSplitter })}
          >
            <Icon name="adjust" />
          </button>
          <button
            className={styles.button}
            onClick={this.props.downloadImage}
            title={intl.formatMessage({ id: this.props.buttonTitleDownload })}
          >
            <Icon name="download" />
          </button>
        </div>
        {/* <div className={styles.rangeWrapper}>
          <InputRange
            defaultValue={this.state.intensity}
            maxValue={100}
            minValue={0}
            step={1}
            onChange={this.props.setIntensity}
          />
        </div> */}
      </>
    );
  }
}

const mapStateToProps = (state: Store) => {
  return {
    intensityLevel: selectIntensityLevel(state),
    isMultiCamera: selectIsMultiCamera(state),
    zoomValue: selectZoomValue(state),
    isVideo: selectIsLive(state),
    buttonTitleRemove: selectCanvasButtonTitleRemove(state),
    buttonTitleZoomIn: selectCanvasButtonTitleZoomIn(state),
    buttonTitleZoomOut: selectCanvasButtonTitleZoomOut(state),
    buttonTitleSplitter: selectCanvasButtonTitleSplitter(state),
    buttonTitleDownload: selectCanvasButtonTitleDownload(state),
    buttonTitleCamera: selectCanvasButtonTitleCamera(state)
  };
};

export default injectIntl(connect(mapStateToProps)(CanvasControls));
