import React, { Component } from "react";
import { connect } from "react-redux";
import { Store } from "redux";
import { Loader } from "semantic-ui-react";
import { Redirect } from "react-router-dom";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";

import { ActionType, ModelDataType } from "types/common";
import {
  selectCurrentModel,
  selectIsLive,
  selectIsVmtReady,
  resetCanvasView,
  selectAccordionsLooks,
  selectAccordionsStatement,
  selectAccordionsProducts
} from "ducks/app";
import CanvasAR from "components/CanvasAR/CanvasAR";
import MakeUpSelector from "components/MakeUpSelector/MakeUpSelector";
import { ROUTES } from "utils/constants";
import LooksSelector from "components/LooksSelector/LooksSelector";
import MobileCart from "components/MobileCart/MobileCart";

import styles from "./ARPage.module.scss";

enum Tabs {
  makeUp = "makeUp",
  looks = "looks"
}

interface Props {
  isVmtReady: boolean;
  isLive: boolean;
  accordionsProducts: string;
  accordionsStatement: string;
  accordionsLooks: string;
  currentModel: ModelDataType | null;
  resetCanvasView: (payload: boolean) => ActionType;
}

interface State {
  openedTab: Tabs;
}

class ARPage extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      openedTab: Tabs.makeUp
    };
  }

  public onTabClick = (tab: Tabs) => () => {
    this.props.resetCanvasView(false);
    this.setState({ openedTab: tab });
  };

  public render() {
    const { openedTab } = this.state;

    if (!this.props.isLive && !this.props.currentModel) {
      return <Redirect to={ROUTES.root} />;
    }

    return (
      <div className={styles.root}>
        <div className={styles.wrapper}>
          <div
            className={classNames(styles.canvasContainer, {
              [styles.live]: this.props.isLive
            })}
          >
            {this.props.isVmtReady ? (
              <CanvasAR />
            ) : (
              <div className={styles.loaderContainer}>
                <Loader active={true} />
              </div>
            )}
          </div>
          <div className={styles.tabsWrapper}>
            <div className={styles.tabsContainer}>
              <div
                className={classNames(styles.tab, {
                  [styles.active]: openedTab === Tabs.makeUp
                })}
                onClick={this.onTabClick(Tabs.makeUp)}
              >
                <FormattedMessage id={this.props.accordionsProducts} />
              </div>
              {/* <div className={styles.splitter}>
                <FormattedMessage id={this.props.accordionsStatement} />
              </div> */}
              {/* <div
                className={classNames(styles.tab, {
                  [styles.active]: openedTab === Tabs.looks
                })}
                onClick={this.onTabClick(Tabs.looks)}
              >
                <FormattedMessage id={this.props.accordionsLooks} />
              </div> */}
            </div>
            <div className={styles.controlWrapper}>
              <div className={styles.makeUpContainer}>
                {openedTab === Tabs.makeUp && <MakeUpSelector />}
                {openedTab === Tabs.looks && <LooksSelector />}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: Store) => ({
  currentModel: selectCurrentModel(state),
  isLive: selectIsLive(state),
  isVmtReady: selectIsVmtReady(state),
  accordionsProducts: selectAccordionsProducts(state),
  accordionsStatement: selectAccordionsStatement(state),
  accordionsLooks: selectAccordionsLooks(state)
});

const mapDispatchToProps = {
  resetCanvasView
};

export default connect(mapStateToProps, mapDispatchToProps)(ARPage);
