export default {
 
  eyelash: [
    {
      isActive: false,
      idx: 0,
      sku_id: "EYELASH_0",
      sku_name: "MIAMI",
      type: "EYELASH",
      r: 35,
      g: 31,
      b: 32,
      a: 0.5,
      defaultOpacity: 0.5,
      mask: "eyelash_14"
    },
    {
      isActive: false,
      idx: 1,
      sku_id: "EYELASH_1",
      sku_name: "PARIS IN 3D FAUX MINK",
      type: "EYELASH",
      r: 35,
      g: 31,
      b: 32,
      a: 0.5,
      defaultOpacity: 0.5,
      mask: "eyelash_15"
    },
    {
      isActive: false,
      idx: 2,
      sku_id: "EYELASH_2",
      sku_name: "CHIC",
      type: "EYELASH",
      r: 35,
      g: 31,
      b: 32,
      a: 0.5,
      defaultOpacity: 0.5,
      mask: "eyelash_16"
    },
    {
      isActive: false,
      idx: 3,
      sku_id: "EYELASH_3",
      sku_name: "FOR LIFE",
      type: "EYELASH",
      r: 35,
      g: 31,
      b: 32,
      a: 0.5,
      defaultOpacity: 0.5,
      mask: "eyelash_17"
    }
  ]
};
